import About from "../about/About";

const AboutUsPage = () => {
    return(
        <>
         <About />
        </>
    )
}

export default AboutUsPage;