import MainPage from "../header/MainPage";
import Mision from "../header/Mision";
import OurServices from "../services/OurServices";

const HomePage = () =>{
    return(
        <>
          <MainPage />
          <Mision />
          <OurServices />
        </>
    )
}

export default HomePage;