import image1 from "../img/pishina-e-germise.jpg";
import fotoja1 from "../img/fotoja1.jpg";
import fotoja2 from "../img/koncerti1.jpg";
import fotoja3 from "../img/koncerti2.jpg";
import fotoja4 from "../img/koncerti4.jpg";
import fotoja5 from "../img/hapjaGermise.jpg";
import fotoja6 from "../img/koncerti6.jpg";
import image2 from "../img/sunset.png";
import verDimer from "../img/maxresdefault.jpg"
import sunset1 from "../img/sunset1.JPG";
import sunset2 from "../img/sunset2.JPG";
import sunset3 from "../img/sunset3.JPG";
import sunset4 from "../img/sunset4.JPG";
import sunset5 from "../img/sunset5.JPG";
import sunset6 from "../img/sunset6.JPG"
import image3 from "../img/fotoja3.jpg";
import basket1 from "../img/basket1.jpg"
import basket2 from "../img/basket2.jpg"
import basket3 from "../img/basket3.jpg";
import basket4 from "../img/basket4.jpg";
import basket5 from "../img/basket5.jpg";
import basket6 from "../img/basket6.jpg";

const DataNews = [{
    id: 4,
    name: "NJOFTIM PËR TREGUN E FUNDVITIT \"VERË N'DIMËR\"",
    photo: verDimer,
    date: "21 Nëntor 2023",
    description: "Komuna e Prishtinës dhe Ndërmarrja Publike Lokale \"Sport Marketing\" njofton qytetarët se për shkak të dekorimeve të fundvitit në sheshin \"George Bush\", jemi të detyruar të shtyejmë datën e hapjes së tregut të fundvitit \"Verë n'Dimër\". Data e hapjes së tregut të fundvitit është 28.11.2023 - 10.01.2024.",

},{
    id:3,
    name: "\"MAMBA Celebrity Game\" për nder të legjendës Kobe Bryant",
    date: "24 Gusht 2023",
    description: "Ndërmarrja Publike Lokale \"Sport Marketing\" në bashkëpunim me \"3x3 Prishtina\" organizuan ndeshjen basketbollistike për nder të legjendës botërore Kobe Bryant, ndeshje kjo e cila u shoqërua me muzikë dhe shumë argëtim për të pranishmit në Amfiteatrin e Gërmisë. Pjesëmarrës të eventit ishin shumë fytyra të njohura të vendit tonë duke përfshirë ish-basketbollistë, gazetarë, artista të ndryshëm, veteranë dhe shumë të pranishëm tjerë që ishin në përcjellje të eventit.",
    photo: image3,
    galery1: basket1,
    galery2: basket2,
    galery3: basket3,
    galery4: basket4,
    galery5: basket5,
    galery6: basket6
},{
    id:2,
    name: "Sunset Party në Pishinën e Gërmisë",
    date: "20 Korrik 2023",
    description: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ullam quisquam pariatur in dolores earum sed adipisci voluptatem nam qui numquam cumque ipsum eligendi soluta nostrum nemo, rem incidunt harum dolore?,                                                 Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ullam quisquam pariatur in dolores earum sed adipisci voluptatem nam qui numquam cumque ipsum eligendi soluta nostrum nemo, rem incidunt harum dolore?                                                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ullam quisquam pariatur in dolores earum sed adipisci voluptatem nam qui numquam cumque ipsum eligendi soluta nostrum nemo, rem incidunt harum dolore?                                                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ullam quisquam pariatur in dolores earum sed adipisci voluptatem nam qui numquam cumque ipsum eligendi soluta nostrum nemo, rem incidunt harum dolore?",
    photo: image2,
    galery1: sunset1,
    galery2: sunset2,
    galery3: sunset3,
    galery4: sunset4,
    galery5: sunset5,
    galery6: sunset6
},{
    id: 1,
    name: "Hapja e Pishinës së Gërmisë",
    photo: image1,
    date: "22 Qershor 2023",
    description: "- Në prani të kryetarit të Komunës së Prishtinës z.Përparim Rama dhe Kryeshefit Ekzekutiv të NPL \"Sport Marketing\" z.Bardh Krasniqi u shënua hapja e pishinës së Gërmisë për vitin 2023. Ky vit pishinën e Gërmisë e gjen me shumë ndryshime si në infrastrukturë ashtu edhe në shërbime tjera që për qëllim kanë ofrimin e kushteve sa më të mira për vizitorët dhe pushuesit. Nga Kryetari i Komunës Përparim Rama dhe Kryeshefi Ekzekutiv Bardh Krasniqi u premtua se çdo vit do të ketë të reja dhe ndryshime në mirëqenien dhe permirësimin e jetës së qytetarëve.",
    galery1: fotoja1,
    galery2: fotoja2,
    galery3: fotoja3,
    galery4: fotoja4,
    galery5: fotoja5,
    galery6: fotoja6
}]


export { DataNews};